<script>
	import files from "./files";
	import { onMount } from 'svelte';

	let pair = {};

	function getRandomInt(max) {
		return Math.floor(Math.random() * (max + 1));
	}

	function setPair() {
		let firstSticker = document.getElementById("first");
		let secondSticker = document.getElementById("second");

		let first = files[getRandomInt(files.length)]
		let second = files[getRandomInt(files.length)]

		firstSticker.src = "/stickers/" + first
		secondSticker.src = "/stickers/" + second

		pair = {first, second}
		console.log(pair)
	}

	function vote(winner) {

		/*let reqBody = {"in_favour": pair.second, "vote": pair.first}

		if(winner === "first") {
			reqBody = {"in_favour": pair.first, "vote": pair.second}
		}
		fetch('/api/vote', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json'
			},
			body: JSON.stringify(reqBody)
		})
		.then((response) => {
			if (response.status > 299) {
				console.log(response)
				alert(response.error())
			}
		})*/

		// Set new pair
		setPair();
	}

	onMount(() => setPair())
</script>

<div id="note">
	<p>Click the hotter si🅱ling to vote when u see it.</p>
	<p>Vuoden 2021 äänestäminen on päättynyt, äänillä ei ole enää vaikutusta.</p>
	<p><a href="/tulokset" style="color: white; font-weight: bold;">tuloksiin</a></p>
</div>

<div id="cont">
	<img id="first" class="display" src="" on:click={() => vote("first")} alt="sticker"/>
	<img id="second" class="display" src="" on:click={() => vote("second")} alt="sticker"/>
</div>

<style>

	div#note{
		width: 100%;
		background: rgba(0,0,0,0.75);
		color: red;
		text-align: center;
		box-sizing: border-box;
		padding: 1rem;
	}

	div#floater h1,p{ margin: 0;}

	div#cont {
		margin: auto;
		width: 512px;
		max-width: 90%;
		max-height: 90%;
		padding: 0;
		padding-top: 1rem;
	}

	div#cont img {

		width: 100%;
		max-height: 50%;
		display: block;
	}

	div#cont img:hover{cursor: pointer;}

	div#cont img#first {margin-bottom: 1rem;}

</style>